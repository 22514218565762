<script setup lang="ts">
const props = defineProps({
   title: { type: String, default: '' },
   backgroundColor: { type: String, default: 'var(--nxt-card-background)' },
   accentColor: { type: String, default: null },
   showMoreOption: { type: Boolean, default: false },
});

const showMore = ref(!props.showMoreOption);
const borderColor = computed(() => props.accentColor);
</script>

<template>
   <div :class="['card', { showAccent: !!accentColor, showMore }]">
      <div v-if="title || $slots.title" class="card__title">
         <slot name="title"> {{ title }} </slot>
      </div>
      <div class="card__content">
         <slot></slot>
      </div>
      <div v-if="showMoreOption" class="card__more">
         <ButtonLink @click="showMore = !showMore">
            {{ showMore ? $t('actions.show-less') : $t('actions.show-more') }}
         </ButtonLink>
      </div>
   </div>
</template>

<style lang="scss" scoped>
.card {
   background-color: v-bind(backgroundColor);
   border-radius: var(--nxt-radius);
   padding: var(--nxt-gutter-small);
   color: var(--nxt-card-color);

   &.showAccent {
      border-left: var(--nxt-card-accent-width) solid v-bind(borderColor);
   }

   &.showMore {
      .card__content {
         max-height: 100%;

         &::after {
            display: none;
         }
      }
   }
}

.card__title {
   font-weight: var(--nxt-font-bold);
   margin-top: 0;
   margin-bottom: toRem(10);
   font-size: toRem(16);
}

.card__content {
   position: relative;
   max-height: toRem(150);
   transition: max-height 0.3s ease;

   &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: toRem(50);
      background: linear-gradient(transparent, var(--nxt-card-background));
   }
}

.card__more {
   text-align: center;
   z-index: var(--zindex-sticky);
}
</style>
