<script lang="ts" setup>
import { CircleStencil, RectangleStencil } from 'vue-advanced-cropper';

// #region Props & Emits
const props = defineProps({
   name: { type: String, required: true },
   disabled: { type: Boolean, default: false },
   label: { type: String, default: '' },
   accept: { type: String, default: 'image/*' },
});
// #endregion

// #region Form Value
const document = defineModel<File | string | null>('modelValue', { default: null });
const documentUrl = ref(typeof document.value === 'string' ? document.value : '');
// #endregion

// #region selectFile
const filePicker = ref<HTMLInputElement | null>(null);
const showCropper = ref(false);

const isImageInput = computed(() => props.accept.includes('image'));

function selectFile(event: Event) {
   if (isImageInput.value) {
      event.preventDefault();
      showCropper.value = true;
   } else {
      filePicker.value?.click();
   }
}

function setImageDocument(dataSrc: string | null) {
   showCropper.value = false;

   if (dataSrc) {
      documentUrl.value = dataSrc;
      document.value = dataSrc;
   }
}

function setDocument(event: Event) {
   event.preventDefault();

   const newFile = (event.target as HTMLInputElement).files?.[0];
   if (!newFile) return;

   document.value = newFile;
}
// #endregion
</script>

<template>
   <div :class="'upload-document'">
      <input ref="filePicker" class="hide-input" :name :type="'file'" :translate="'yes'" :accept @change="setDocument" />
      <ButtonLink
         class="upload-document__picker"
         :disabled
         :type="'button'"
         :background-color="'var(--nxt-extralight-grey)'"
         @click="selectFile"
      >
         {{ $t('actions.choose-file') }}
      </ButtonLink>

      <div v-if="isImageInput && documentUrl" class="upload-document__preview">
         <NuxtImg :src="documentUrl" />
      </div>

      <div v-else-if="document && typeof document === 'object'">
         <p>{{ document.name }}</p>
      </div>

      <Teleport to="body">
         <ModalImageCropper
            v-if="showCropper"
            :src="documentUrl"
            :title="label"
            :loading="disabled"
            :stencil-component="name === 'profile_picture_url' ? CircleStencil : RectangleStencil"
            @on-close="showCropper = false"
            @on-save="setImageDocument"
         />
      </Teleport>
   </div>
</template>

<style lang="scss" scoped>
.upload-document {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
   border: 1px solid var(--nxt-grey);
   border-radius: var(--nxt-radius);
   padding: var(--nxt-gutter-small);

   &__preview {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
         max-height: 200px;
         object-fit: contain;
         max-width: 100%;
      }
   }
}

.upload-document__picker {
   width: 100%;
}
</style>
