import imageCompression, { type Options } from 'browser-image-compression';

export function changeImageResolution(image: File, options: Options = { maxSizeMB: 4, maxWidthOrHeight: 1080 }): Promise<File> {
   return imageCompression(image, options);
}

export async function base64ToBlob(base64: string): Promise<Blob> {
   const blob = await fetch(base64).then((res) => res.blob());

   return blob;
}

export async function fileToBase64(file: Blob): Promise<string> {
   return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
   });
}

export async function base64ToCompressedFile(base64: string, options: Options = { maxSizeMB: 0.5, maxWidthOrHeight: 1080 }) {
   const blob = await base64ToBlob(base64);
   const file = new File([blob], 'image.png', { type: 'image/png' });

   return changeImageResolution(file, options);
}
