import type { TenantStyle } from '~/models/tenant-branding.interface';

export const MY_AGO_BRANDING: TenantStyle = {
   tenantName: 'My Ago',
   themeColor: '#003b5c',
   bannerImage: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/keycloak/myago/banner.mp4',
   fontUrl: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/fonts/Lato_NXT/Lato_NXT.css',
   showPositions: true,
   files: {
      termsAndConditionsUrl: (locale) => {
         if (locale === 'fr') return 'https://www.ago.jobs/fr/conditions';
         if (locale === 'nl') return 'https://www.ago.jobs/nl/voorwaarden';
         return 'https://www.ago.jobs/en/terms';
      },
      privacyPolicyUrl: (locale) => {
         if (locale === 'fr') return 'https://www.ago.jobs/fr/privacy-policy';
         if (locale === 'nl') return 'https://www.ago.jobs/nl/privacy-policy';
         return 'https://www.ago.jobs/en/privacy-policy';
      },
      cookiePolicyUrl: (locale) => {
         if (locale === 'fr') return 'https://www.ago.jobs/fr/cookie-policy';
         if (locale === 'nl') return 'https://www.ago.jobs/nl/cookie-policy';
         return 'https://www.ago.jobs/en/cookie-policy';
      },
   },
   cssProperties: {
      '--nxt-main': 'var(--ago-main)',
      '--nxt-secondary': 'var(--ago-secondary)',
      '--nxt-secondary-text': 'var(--nxt-white)',
      '--nxt-main-highlight': 'var(--ago-main-highlight)',
      '--nxt-main-overlay': 'var(--ago-main-overlay)',
      '--nxt-base-header-background': 'var(--ago-main)',
      '--nxt-base-header-color': 'var(--nxt-white)',
      '--nxt-base-background': 'var(--nxt-extralight-grey)',
      '--nxt-card-background': 'var(--nxt-white)',
      '--nxt-special-background': "var(--nxt-extralight-grey) url('/myago-icons/background.svg') no-repeat left bottom ",

      '--nxt-font-family': 'var(--ago-font-family)',
   },
};
