export interface PersonEndpoints extends Pick<Endpoints, 'PATCH' | 'GET'> {
   UPLOAD_PROFILE_PICTURE: () => string;
   UPLOAD_IDENITITY_CARD: () => string;
}

export const PERSON_ENDPOINTS: PersonEndpoints = {
   PATCH: () => '/person',
   GET: (personSyncId) => `/person/${personSyncId}`,
   UPLOAD_PROFILE_PICTURE: () => '/profile-picture',
   UPLOAD_IDENITITY_CARD: () => '/identity-card',
};
