<script lang="ts" setup>
// #region Globals
const { showPositions } = useBranding();
// #endregion

// #region Props & emits
const emits = defineEmits<{
   onClose: [void];
}>();
// #endregion

// #region User Info
const userInfoStore = useUserInfoStore();
const { userInfo } = storeToRefs(userInfoStore);

// #endregion

// #region Profile
const profilePicutureUrl = computed(() => userInfo.value?.person?.profile_picture_url ?? '');
const fullName = computed(() => `${userInfo.value?.person?.first_name ?? ''} ${userInfo.value?.person?.last_name ?? ''}`);
const isProfilePage = computed(() => useRoute().name?.toString().startsWith('profile'));
// #endregion
</script>

<template>
   <ModalSide @on-close="emits('onClose')">
      <template #default="{ close }">
         <div :class="'navigation-show-more-menu'">
            <LayoutNavigationItem
               v-if="showPositions"
               :class="'navigation__items__item'"
               :to="'/jobs-and-applications'"
               :icon-name="'jobs'"
               :icon-style="'fa-kit'"
               :label="$t('jobs.job', 2)"
               @click="close"
            />

            <LayoutNavigationItem
               :class="'navigation__items__item'"
               :to="`/documents`"
               :icon-name="'files'"
               :label="$t('documents.document', 2)"
               @click="close"
            />
            <LayoutNavigationItem :class="'navigation__items__item -profile'" :to="`/profile`" :label="$t('profile.profile')">
               <LayoutCircularLogo
                  v-if="userInfo"
                  :height="isProfilePage ? 40 : 45"
                  :width="isProfilePage ? 40 : 45"
                  :name="fullName"
                  :logo="profilePicutureUrl"
                  :object-fit="'cover'"
                  @click="close"
               />
            </LayoutNavigationItem>
         </div>
      </template>
   </ModalSide>
</template>

<style lang="scss" scoped>
.navigation-show-more-menu {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr 1fr;
   gap: var(--nxt-gutter-large);
   padding: var(--nxt-gutter-small);
   width: 100%;
}
</style>
