<script lang="ts" setup>
import CountryFlag from 'vue-country-flag-next';

// #region Global
const userInfoStore = useUserInfoStore();
const { t } = useI18n();
const person = computed(() => userInfoStore.userInfo?.person);
// #endregion

// #region Props & Emits
const props = defineProps<{ baseKey: string }>();
const modelValue = defineModel<Partial<Address>>('modelValue');

function setAddress(key: keyof Address, value: any) {
   if (!modelValue.value) {
      modelValue.value = {
         [key]: value,
      };
   } else {
      modelValue.value[key] = value;
   }
}
// #endregion

// #region City
function setCity(city: City) {
   if (!modelValue.value) modelValue.value = {} as Address;
   modelValue.value.city_id = city?.id;
   modelValue.value.city = city;
}
// #endregion

// #region Title
const title = computed(() => {
   if (props.baseKey === 'official_address') {
      return t('profile.official-address');
   }
   return t('profile.residential-address');
});
// #endregion

// #region Same as official address
const isSameAsOfficialAddress = ref(props.baseKey === 'residential_address' ? !person.value?.residential_address : false);

function toggleIsSame() {
   isSameAsOfficialAddress.value = !isSameAsOfficialAddress.value;

   if (isSameAsOfficialAddress.value) {
      modelValue.value = null;
   } else {
      modelValue.value = {
         street: null,
         city_id: null,
         house_number: null,
      } as any;
   }
}
// #endregion

// #region Aws Locations
const selectedPlaceId = ref<string>();

const { places, currentPosition, searchPlaces, getPlaceById, getCurrentPosition } = useLocationService();

async function updateSelectedPlace() {
   if (!selectedPlaceId.value) return;

   const address = await getPlaceById(selectedPlaceId.value);

   if (address) {
      modelValue.value = { ...modelValue.value, ...address };
   }
}

watchEffect(updateSelectedPlace);
// #endregion

// #region Address Details
const showAddressDetails = ref(false);

function checkAddressDetails(address?: Partial<Address>) {
   showAddressDetails.value = Object.values(address ?? {}).some((value) => !!value);
}

watch(modelValue, checkAddressDetails, { immediate: true });
// #endregion

// #region Get Current Location
const locationPermission = usePermission('geolocation');

function autoDetectLocation() {
   if (locationPermission.value !== 'granted') return;

   getCurrentPosition();
}

watch(locationPermission, autoDetectLocation, { immediate: true });
// #endregion
</script>

<template>
   <LayoutCard class="precognition-address-form" :background-color="'var(--nxt-white)'">
      <div class="precognition-address-form__heading">
         <strong v-if="title">{{ title }}</strong>

         <PrecognitionFormElement
            v-if="baseKey === 'residential_address'"
            :name="baseKey + '.is_same_as_official'"
            :label="$t('profile.same-as-official-address')"
         >
            <PrecognitionFormSwitch :model-value="isSameAsOfficialAddress" @update:model-value="toggleIsSame" />
         </PrecognitionFormElement>

         <FormElement v-if="!isSameAsOfficialAddress" :name="'search'">
            <FormSelect
               v-model="selectedPlaceId"
               :search-type="'custom'"
               :options="places"
               :placeholder="$t('actions.search-type', { type: $t('generic.address') })"
               :value-key="'PlaceId'"
               :label-key="'Text'"
               :has-clear-option="false"
               @on-search="searchPlaces($event)"
            >
               <template v-if="locationPermission && !currentPosition" #before>
                  <div class="precognition-address-form__location" @click="getCurrentPosition">
                     <i class="fa-solid fa-location-crosshairs"></i>
                     <span>{{ $t('generic.use-location-for-better-results') }}</span>
                  </div>
               </template>
            </FormSelect>
         </FormElement>
      </div>

      <Transition name="fade">
         <div v-if="!isSameAsOfficialAddress && showAddressDetails" class="precognition-address-form__inputs">
            <PrecognitionFormElement :name="baseKey + '.street'" :label="$t('profile.street')">
               <PrecognitionFormInput :model-value="modelValue?.street" @update:model-value="setAddress('street', $event)" />
            </PrecognitionFormElement>

            <PrecognitionFormElement :name="baseKey + '.house_number'" :label="$t('profile.house-number')">
               <PrecognitionFormInput :model-value="modelValue?.house_number" @update:model-value="setAddress('house_number', $event)" />
            </PrecognitionFormElement>

            <PrecognitionFormElement :name="baseKey + '.box'" :label="$t('profile.box')">
               <PrecognitionFormInput :model-value="modelValue?.box" @update:model-value="setAddress('box', $event)" />
            </PrecognitionFormElement>

            <PrecognitionFormElement :name="baseKey + '.city_id'" :label="$t('profile.city')">
               <PrecognitionFormSelect
                  :model-value="modelValue?.city_id"
                  :search-type="'custom'"
                  :search-url="CITY_ENDPOINTS.GET()"
                  :emit-object="true"
                  :options="modelValue?.city ? [modelValue?.city] : []"
                  @update:model-value="setCity"
               >
                  <template #displayOption="{ option }">
                     <div class="precognition-address-form__city">
                        <CountryFlag
                           v-if="option.country"
                           class="precognition-address-form__country-flag"
                           :country="option.country.iso_code_2"
                        />
                        <span>{{ option.name }} {{ option.postal_code }}</span>
                        <span v-if="option.country">, {{ option.country.name }}</span>
                     </div>
                  </template>
                  <template #displaySelected="{ option }">
                     <div class="precognition-address-form__city">
                        <CountryFlag
                           v-if="option.country"
                           class="precognition-address-form__country-flag"
                           :country="option.country.iso_code_2"
                        />
                        <span>{{ option.name }} {{ option.postal_code }}</span>
                        <span v-if="option.country">, {{ option.country.name }}</span>
                     </div>
                  </template>
               </PrecognitionFormSelect>
            </PrecognitionFormElement>
         </div>
      </Transition>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.precognition-address-form__location {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   padding: var(--nxt-gutter) var(--nxt-gutter-small);
   cursor: pointer;
   &:hover {
      background-color: var(--nxt-light-grey);
   }
}

.precognition-address-form {
   border: 1px solid var(--nxt-grey);
   background-color: var(--nxt-white);
   display: flex;
   flex-direction: column;
}
.precognition-address-form__heading {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
}
.precognition-address-form__inputs {
   border-top: 1px solid var(--nxt-grey);
   padding: 0 var(--nxt-gutter-small);
   margin: 0 var(--nxt-gutter-small--negative);
   margin-top: var(--nxt-gutter-small);
   padding-top: var(--nxt-gutter-small);
   display: flex;
   flex-wrap: wrap;
   gap: var(--nxt-gutter-small);

   > *:first-of-type {
      width: 100%;
   }

   > * {
      width: calc((100% - var(--nxt-gutter-small)) / 2);
   }

   > *:last-of-type {
      width: 100%;
   }
}

.precognition-address-form__city {
   display: flex;
   align-items: center;
   height: toRem(18);

   span {
      white-space: nowrap;
   }
}

.precognition-address-form__country-flag {
   box-shadow: var(--nxt-shadow-small);
   transform: scale(0.3) !important;
   margin: 0em -0.9em -0em -1.2em !important;
}
</style>
