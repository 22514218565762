<script lang="ts" setup>
import { usePersonTodoForm } from './composables/usePersonTodoForm';

// #region Globals
const { resetMyWeek } = useResetState();
// #endregion

// #region Props & Emits
const emits = defineEmits<{
   (e: 'onClose'): void;
}>();
const props = defineProps({
   id: { type: String, default: null },
});
// #endregion

// #region  person todo form
const { data: personTodo } = await useAuthFetch<PersonTodo>(PERSON_TODO_ENDPOINTS.GET(props.id), { lazy: false });
const { form, error, hasChanges, sections, resources, confirmPersonTodo, uploadFiles } = usePersonTodoForm(personTodo);

function handleErrors(exception: any) {
   try {
      const errors = Object.entries(exception?.response?.data?.errors || {});
      if (!errors.length) return;

      for (const [key] of errors) {
         const el = document.querySelector(`[name="${key}"]`);
         if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            break;
         }
      }
   } catch (e) {
      console.warn('An error occurred while handling errors:', e);
   }
}
// #endregion

// #region Submit
const saving = ref(false);

async function submit() {
   try {
      saving.value = true;
      await uploadFiles();

      const { data } = (await form.submit()) as ApiResponse<Person>;
      form.setData(data as Partial<Person>);

      await confirmPersonTodo();
      if (!error.value) {
         resetMyWeek();
         emits('onClose');
      }
   } catch (e: any) {
      handleErrors(e);
   } finally {
      saving.value = false;
   }
}
// #endregion
</script>

<template>
   <form @submit.stop.prevent="submit">
      <ModalSide
         :title="$t('profile.person-todo-title')"
         :class="'person-todo-modal'"
         :max-height="'var(--nxt-modal-layer-1)'"
         :min-height="'30rem'"
         :height="'fit-content'"
         :saving="saving"
         :has-unsaved-changes="hasChanges"
         @on-close="emits('onClose')"
      >
         <div v-auto-animate class="person-todo-modal__form">
            <div v-for="section in sections" :key="section.name">
               <PersonTodoCustomSection v-if="section.isCustomSection && form" v-model="form" :section="section" />

               <LayoutSection v-else :title="section.name">
                  <div v-auto-animate class="person-todo-modal__form__list">
                     <template v-for="question in section.questions" :key="question.key">
                        <PersonTodoQuestion
                           v-model="form[question.key]"
                           :question="question"
                           :resource="question.resource_endpoint ? resources[question.resource_endpoint] : null"
                        />
                     </template>
                  </div>
               </LayoutSection>
            </div>
         </div>

         <template #actions>
            <ButtonMain :disabled="form.validating || form.processing || saving" type="submit"> {{ $t('actions.save') }} </ButtonMain>
         </template>
      </ModalSide>
   </form>
</template>

<style lang="scss" scoped>
.person-todo-modal__form {
   display: flex;
   flex-direction: column;

   gap: var(--nxt-gutter);
}

.person-todo-modal__form__list {
   display: flex;
   flex-direction: column;

   gap: var(--nxt-gutter);
}
</style>
