import type { TenantStyles } from '~/models/tenant-branding.interface';

import { GOTEMP_BRANDING } from './gotemp.branding';
import { MY_AGO_BRANDING } from './myago.branding';
import { MY_LETS_WORK_BRANDING } from './myletswork.branding';
import { NXT_PLAN_BRANDING } from './nxt-plan.branding';

const tenantKeys = ['nxt-plan', 'gotemp', 'myletswork', 'myago'];

const tenantBrandings: TenantStyles = {
   'nxt-plan': NXT_PLAN_BRANDING,
   gotemp: GOTEMP_BRANDING,
   myletswork: MY_LETS_WORK_BRANDING,
   myago: MY_AGO_BRANDING,
};

export { tenantBrandings, tenantKeys };
