import type { TenantStyle } from '~/models/tenant-branding.interface';

export const GOTEMP_BRANDING: TenantStyle = {
   tenantName: 'GoTemp',
   themeColor: '#081c4a',
   fontUrl: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/fonts/Nunito_NXT/Nunito_NXT.css',
   bannerImage: 'https://nxtpeople-cdn.s3.eu-central-1.amazonaws.com/keycloak/gotemp/banner-video.mp4',
   showPositions: true,
   files: {
      termsAndConditionsUrl: (locale) => {
         return `https://www.nxtpeople.com/assets/files/${locale}/NXTPeople_Terms_Conditions-2023.pdf`;
      },
      privacyPolicyUrl: () => {
         return `${window.location.origin}/privacy-policy`;
      },
      cookiePolicyUrl: (locale) => {
         return `https://www.nxtpeople.com/assets/files/${locale}/NXTPeople_Cookie_policy.pdf`;
      },
   },
   cssProperties: {
      '--nxt-main': 'var(--gotemp-main)',
      '--nxt-secondary-text': 'var(--nxt-dark)',
      '--nxt-main-highlight': 'var(--gotemp-main-highlight)',
      '--nxt-main-overlay': 'var(--gotemp-main-overlay)',
      '--nxt-base-header-background': 'var(--nxt-dark)',
      '--nxt-base-header-color': 'var(--nxt-white)',
      '--nxt-base-background': 'var(--nxt-white)',
      '--nxt-base-color': 'var(--nxt-dark)',
      '--nxt-special-background': 'var(--nxt-white)',

      '--nxt-radius-small': '0.33rem',
      '--nxt-radius': '0.5rem',
      '--nxt-radius-large': '0.89rem',

      '--nxt-font-family': 'var(--gotemp-font-family)',
   },
};
