import {
   LocationClient,
   SearchPlaceIndexForSuggestionsCommand,
   SearchPlaceIndexForTextCommand,
   type SearchForSuggestionsResult,
} from '@aws-sdk/client-location';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
import { Map, Marker, type LngLatLike, type MapOptions } from 'maplibre-gl';

export function useLocationService() {
   // #region globals
   const { awsLocationsApiKey, awsPlacesIndex, awsRegion, awsMapName } = useRuntimeConfig().public;

   const client = ref<LocationClient>();
   // #endregion

   const currentPosition = ref<[number, number]>();

   async function getCurrentPosition(): Promise<[number, number] | undefined> {
      try {
         const position: GeolocationPosition = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => resolve(position), reject);
         });

         currentPosition.value = [position.coords.longitude, position.coords.latitude];
         return currentPosition.value;
      } catch {
         console.warn(`Failed to get current position`);
         return undefined;
      }
   }

   // #region Lifecycle
   async function setup() {
      try {
         const auth = await withAPIKey(awsLocationsApiKey);
         client.value = new LocationClient({
            region: awsRegion,
            ...auth.getLocationClientConfig(),
         });
      } catch {
         console.warn('Could not setup location service');
      }
   }

   onUnmounted(() => {
      client.value?.destroy();
   });
   // #endregion

   // #region search places
   const input = ref('');
   const places = ref<Array<SearchForSuggestionsResult>>();
   const error = ref<any>();

   const searchPlaces = useDebounceFn(async (input?: string) => {
      try {
         if (!client.value) await setup();

         const command = new SearchPlaceIndexForSuggestionsCommand({
            Text: input,
            IndexName: awsPlacesIndex,
            MaxResults: 10,
            BiasPosition: currentPosition.value,
         });

         places.value = (await client.value!.send(command))?.Results ?? [];
         return places.value;
      } catch (e) {
         error.value = e;
      }
   }, 200);

   watch(input, searchPlaces);
   // #endregion

   // #region Get Place Details
   async function getPlaceById(placeId: string) {
      try {
         const res = await authFetch<ApiResponse<Address>>(`/places/${placeId}`);
         return res?.data;
      } catch (e) {
         return null;
      }
   }
   // #endregion

   // #region Geocode
   async function getGeocode(place: string) {
      try {
         if (!client.value) await setup();

         const command = new SearchPlaceIndexForTextCommand({
            IndexName: awsPlacesIndex,
            Text: place,
            MaxResults: 1,
         });
         const response = await client.value!.send(command);
         return response?.Results?.[0]?.Place?.Geometry?.Point as LngLatLike;
      } catch (e) {
         error.value = e;
      }
   }
   // #endregion

   // #region Display Map
   const mapContainer = ref<HTMLElement>();
   const map = ref<Map>();
   const mapLoading = ref(false);

   async function initMap(mapOptions: Partial<MapOptions>) {
      try {
         await nextTick();
         if (!mapContainer.value) return;

         mapLoading.value = true;

         map.value = new Map({
            ...mapOptions,
            container: mapContainer.value,
            maplibreLogo: false,
            attributionControl: false,
            style: `https://maps.geo.${awsRegion}.amazonaws.com/maps/v0/maps/${awsMapName}/style-descriptor?key=${awsLocationsApiKey}`,
         });

         map.value.on('load', () => {
            mapLoading.value = false;
         });

         return map.value;
      } catch {
         mapLoading.value = false;
      }
   }

   function addMarker(point: LngLatLike) {
      if (!map.value) return;

      const marker = new Marker().setLngLat(point).addTo(map.value);

      return marker;
   }

   // #endregion

   return {
      currentPosition,
      mapContainer,
      map,
      input,
      places,
      error,
      searchPlaces,
      getPlaceById,
      initMap,
      addMarker,
      getGeocode,
      getCurrentPosition,
   };
}
