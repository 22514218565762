<script setup lang="ts">
// #region Globals
const { t } = useI18n();
const optionsToDisplay = ref<Array<any>>([]);
const searchRef = ref<HTMLInputElement | null>(null);
// #endregion

// #region Props & Emits
const props = defineProps({
   modelValue: { type: [String, Number, Object, Boolean] as PropType<string | number | object | boolean | null>, default: null },
   options: { type: Array as PropType<Array<any>>, default: () => [] },
   valueKey: { type: String, default: 'id' },
   labelKey: { type: String, default: 'name' },
   searchUrl: { type: String, default: null },
   searchType: { type: String as PropType<'custom' | 'internal'>, default: null },
   optionsToLoadInitially: { type: Number, default: 25 },
   emitObject: { type: Boolean, default: false },
   placeholder: { type: String, default: null },
   tabindex: { type: Number, default: 0 },
   group: {
      type: Object as PropType<{
         groupValueKey: string;
         groupLabelKey: string;
         groupListKey: string;
      }>,
      default: null,
   },
});

const emits = defineEmits<{
   (e: 'update:modelValue', value?: any): void;
   (e: 'onSearch', value?: string): void;
}>();
// #endregion

// #region Element
const { name, invalid, required, disabled, label, validate, touch } = usePrecognitionFormElement();
// #endregion

// #region ModelValue
const selectedOption = ref(props.options.find((o) => getValueKey(o) === props.modelValue) || null);

function updateSelectedOption() {
   searchRef.value?.scrollIntoView({ block: 'nearest' });
   if (optionsToDisplay.value.length === 0) return;
   const match = optionsToDisplay.value.find((o) => getValueKey(o) === props.modelValue);

   if (match) {
      selectedOption.value = match;
   }
}

// #endregion

// #region Options

function updateOptionsToDisplay(options: Array<any>, oldOptions?: Array<any>) {
   if (deepEqual(options, oldOptions)) return;
   if (options.length === 0 && selectedOption.value) {
      const containsSelectedOption = optionsToDisplay.value?.find((o) => getValueKey(o) === getValueKey(selectedOption.value));
      if (!containsSelectedOption) optionsToDisplay.value = [selectedOption.value];
   } else {
      optionsToDisplay.value = JSON.parse(JSON.stringify(options));
   }
}

watch(() => props.options, updateOptionsToDisplay, { immediate: true });
watch(optionsToDisplay, updateSelectedOption, { immediate: true });
// #endregion

// #region Dropdown
const isOpen = ref(false);
// #endregion

// #region Search
const loading = ref(false);
const search = ref('');

function filteredOptions(options: Array<any>, searchValue?: string) {
   const localOptions = JSON.parse(JSON.stringify(options));
   return localOptions.filter((o: any) => {
      const displayKey = getDisplayKey(o);
      return displayKey?.toLowerCase().includes(searchValue?.toLowerCase());
   });
}

function filterGroups(groups: Array<any>, searchValue?: string) {
   const localGroups = JSON.parse(JSON.stringify(groups));
   return localGroups.map((g: any) => {
      const options = filteredOptions(getPropertyByPath(g, props.group.groupListKey) || [], searchValue);
      return updatePropertyByPath(g, props.group.groupListKey, options);
   });
}

function internalSearch(options: Array<any>, searchValue?: string) {
   if (props.group) {
      optionsToDisplay.value = filterGroups(options, searchValue);
   } else {
      optionsToDisplay.value = filteredOptions(options, searchValue);
   }
}

async function searchByUrl(searchValue?: string) {
   if (!props.searchUrl) return;

   try {
      loading.value = true;

      const { data } = await useAuthFetch<any>(props.searchUrl, {
         method: 'GET',
         params: {
            search: searchValue?.trim() || undefined,
            per_page: props.optionsToLoadInitially || undefined,
         },
         lazy: false,
      });

      if (Array.isArray(data?.value)) {
         optionsToDisplay.value = data?.value || [];
      } else if (Array.isArray(data.value.data)) {
         optionsToDisplay.value = data?.value?.data || [];
      } else {
         optionsToDisplay.value = [];
      }
   } finally {
      loading.value = false;
   }
}

function customSearch(searchValue?: string) {
   emits('onSearch', searchValue);
}

function searchOptions(search?: string) {
   const lowerCaseSearch = search?.toLowerCase().trim();

   if (props.searchType === 'internal') {
      return internalSearch(props.options, lowerCaseSearch);
   }

   if (props.searchUrl) {
      return searchByUrl(lowerCaseSearch);
   }

   return customSearch(lowerCaseSearch);
}

watch(isOpen, (isOpen, oldValue) => {
   if (isOpen && oldValue !== undefined) {
      setTimeout(async () => {
         await nextTick();
         searchRef.value?.focus();
      }, 300);
   }
});

onMounted(() => {
   if (props.searchUrl && props.optionsToLoadInitially) {
      searchOptions();
   }
});

watch(search, searchOptions);

function handleUpdate(value: any) {
   emits('update:modelValue', props.emitObject ? value : getValueKey(value));
   selectedOption.value = value;
   isOpen.value = false;

   validate();
   touch();
}

function getValueKey(option: any) {
   return getPropertyByPath(option, props.valueKey);
}

function getDisplayKey(option: any) {
   return getPropertyByPath(option, props.labelKey);
}

// #endregion
</script>

<template>
   <div :id="name" :class="['precognition-select__container', { disabled }]">
      <input
         class="hide-input"
         :name="name"
         :value="props.emitObject ? selectedOption : getValueKey(selectedOption)"
         @focus="isOpen = true"
      />
      <div
         :tabindex="tabindex"
         :class="['precognition-select', { invalid, disabled }]"
         @click="isOpen = true"
         @keydown.enter="isOpen = true"
      >
         <slot v-if="selectedOption || modelValue" name="displaySelected" v-bind="{ option: selectedOption }">
            <p>{{ getDisplayKey(selectedOption) || getDisplayKey(modelValue) }}</p>
         </slot>
         <div v-else class="precognition-select__placeholder">{{ t('actions.select') }}</div>
         <i
            v-if="searchType === 'custom' && !options.length"
            :class="'fas fa-magnifying-glass'"
            :style="{ color: 'var(--nxt-dark-grey)' }"
         />
         <i v-else :class="['fas fa-chevron-down', { '-open': isOpen }]" />
      </div>

      <!-- Options List -->
      <Teleport v-if="isOpen" to=".app">
         <ModalSide
            :title="placeholder || label || $t('actions.select')"
            class="precognition-select__options__modal"
            :min-height="'var(--nxt-modal-layer-3)'"
            :height="'var(--nxt-modal-layer-3)'"
            :width="'25vw'"
            @on-close="isOpen = false"
         >
            <div
               v-auto-animate
               :class="['precognition-select__options', `search-${searchType}`]"
               :style="{ minHeight: searchUrl ? '15rem' : 'unset' }"
            >
               <div v-show="searchUrl || searchType" class="precognition-select__input">
                  <input ref="searchRef" v-model="search" :placeholder="t('actions.search')" :autofocus="true" />
                  <Transition name="fade">
                     <i v-if="loading" class="fa-solid fa-spinner fa-spin"></i>
                  </Transition>
               </div>

               <TransitionGroup v-if="!group" name="fade">
                  <div v-if="!required" :key="'clear'" class="precognition-select__option" @click="handleUpdate(null)">
                     <slot name="clearText">{{ t('actions.clear') }}</slot>
                  </div>
                  <div
                     v-for="(option, index) in optionsToDisplay"
                     :key="getValueKey(option) || index"
                     class="precognition-select__option"
                     @click="handleUpdate(option)"
                  >
                     <slot name="displayOption" v-bind="{ option }">{{ getDisplayKey(option) }}</slot>
                  </div>
               </TransitionGroup>

               <TransitionGroup v-else name="fade">
                  <div v-for="(g, groupIndex) in optionsToDisplay" :key="getPropertyByPath(g, group.groupValueKey || 'id') || groupIndex">
                     <div class="precognition-select__option-group__title">
                        {{ getPropertyByPath(g, group.groupLabelKey || 'name') }}
                     </div>

                     <div
                        v-for="(option, optionIndex) in getPropertyByPath(g, group.groupListKey || 'options')"
                        :key="`${groupIndex}-${getValueKey(option) || optionIndex}`"
                        class="precognition-select__option-group__option"
                        @click="handleUpdate(option)"
                     >
                        <slot name="displayOption" v-bind="{ option }">{{ getDisplayKey(option) }}</slot>
                     </div>
                  </div>
               </TransitionGroup>
            </div>
         </ModalSide>
      </Teleport>
   </div>
</template>

<style lang="scss" scoped>
.precognition-select__container {
   width: 100%;
}
.precognition-select {
   position: relative;
   width: 100%;
   height: toRem(40);
   border: 1px solid var(--nxt-grey);
   border-radius: var(--nxt-radius);
   background: #f6f7fa;
   padding-left: var(--nxt-gutter-small);
   display: flex;
   align-items: center;
   outline: none;
   padding-right: var(--nxt-gutter-large);

   p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }

   i {
      position: absolute;
      right: var(--nxt-gutter-small);
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      transition: transform 0.3s ease;
      &.-open {
         transform: translateY(-50%) rotate(180deg);
      }
   }

   &.invalid {
      border-color: var(--nxt-red);
   }

   &.disabled {
      color: var(--nxt-dark-grey);
      pointer-events: none;
   }
}

.precognition-select__placeholder {
   color: var(--nxt-dark-grey);
}
.precognition-select__options {
   display: flex;
   flex-direction: column;

   &:not(.search-none) {
      .precognition-select__option-group__title {
         top: calc(toRem(38) + toRem(46));
      }
   }
}

.precognition-select__options__loading {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
}
.precognition-select__option-group__title {
   padding: var(--nxt-gutter) var(--nxt-gutter-small);
   font-weight: bold;
   color: var(--nxt-dark-grey);
   position: sticky;
   top: toRem(46);
   background-color: var(--nxt-white);
   z-index: 10;
}

.precognition-select__option,
.precognition-select__option-group__option {
   padding: var(--nxt-gutter) var(--nxt-gutter-small);
   cursor: pointer;
   &:hover {
      background-color: var(--nxt-light-grey);
   }
}

.precognition-select__input {
   position: sticky;
   top: 0;
   background: var(--nxt-white);
   display: flex;
   z-index: var(--zindex-sticky);

   input {
      padding-right: toRem(35);
   }

   i {
      position: absolute;
      right: 0.8rem;
      top: 0.8rem;
      transform: translateY(-50%);
      pointer-events: none;
   }
}
</style>
