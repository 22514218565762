import type { UserInfo } from '../user-info/user-info.interface';

export enum PersonTodoFormType {
   Text = 'text',
   Date = 'date',
   Switch = 'switch',
   Dropdown = 'dropdown',
   DropdownSearch = 'dropdown_search',
   Custom = 'custom',
   PhoneNumber = 'phone_number',
   Checkbox = 'checkbox',
   File = 'file',
}

export interface PersonTodoQuestion {
   key: keyof Person;
   name: string;
   resource_endpoint: string | null;
   form_type: PersonTodoFormType;
}

export interface PersonTodoSection {
   name: string;
   questions: PersonTodoQuestion[];
}

export interface PersonTodoTab {
   name: string;
   sections: PersonTodoSection[];
}

export interface PersonTodoChapter {
   id: number;
   key: string;
   name: string;
   tabs: PersonTodoTab[];
}

export interface PersonTodo {
   sync_id: string;
   chapter_id: number;
   chapter: PersonTodoChapter;
   creator_user_id: number | null;
   creator_user: UserInfo;
   created_at: string;
}
